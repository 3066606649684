<a href="https://github.com/irclogs/svelte">
  <img src="./img/green-black.png" alt="Fork me on GitHub" />
</a>

<style>
  a {
    position: absolute;
    top: 0;
    right: 20px;
    border: 0;
  }
</style>
