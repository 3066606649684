<svelte:head><title>404 Not found!</title></svelte:head>

<div class="outer"><div class="inner">404 Not found!</div></div>

<style>
  div.outer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  div.inner {
    font-size: 22pt;
    font-weight: bold;
    font-family: monospace;
    color: #9e9e9e;
  }
</style>
